<template>
  <div class="h-100 d-flex flex-column">
    <Auth
      v-model:phone="phone"
      v-model:code="code"
      heading="Welcome back!"
      message="Verify your phone number to pick up where you left off."
      label="Continue"
      :sent="sent"
      :disabled="submitting"
      @send="onSend"
      @resend="onResend"
      @submit="onSubmit"
    />
    <Alert
      v-model="serverError"
      text="We encountered an error. Technical support has been notified."
    />
    <Alert
      v-model="lookupError"
      text="There is no application linked to this number."
    />
    <Alert
      v-model="authError"
      text="Verification code incorrect."
    />
  </div>
</template>

<script setup>
import get from 'lodash/get';
import { useNurlaStore } from '@/store';
import { getIp, validateAndSendCode, login, setToken, dispatch } from '@/services';
import { LOGGED_IN } from '@/services/state';

useHead({
  title: 'Login',
});

const nurlaStore = useNurlaStore();

const sent = ref(false);
const submitting = ref(false);
const serverError = ref(false);
const lookupError = ref(false);
const authError = ref(false);

const phone = ref();
const code = ref();
const ip = ref();

onMounted(async () => {
  ip.value = await getIp();
});

async function onSend () {
  submitting.value = true;
  try {
    const payload = {
      phone: phone.value,
    };
    await validateAndSendCode(payload);
    nurlaStore.setUser({ ip: ip.value });
    sent.value = true;
    submitting.value = false;
  } catch (e) {
    useBugsnag().notify(e);
    if (get(e, 'response.status') === 401) {
      lookupError.value = true;
    } else {
      serverError.value = true;
    }
    submitting.value = false;
  }
}

async function onResend () {
  authError.value = false;
  submitting.value = true;
  // Resend, clear code input
  code.value = null;
  const payload = {
    phone: phone.value,
  };
  try {
    await validateAndSendCode(payload);
    submitting.value = false;
  } catch (e) {
    useBugsnag().notify(e);
    serverError.value = true;
    submitting.value = false;
  }
}

async function onSubmit () {
  submitting.value = true;
  const payload = {
    phone: phone.value,
    code: code.value,
  };
  try {
    // Verify code / login
    const token = await login(payload);
    await setToken(token);

    const { payload: { id, phone } } = parseJwt(token);
    nurlaStore.setUser({ id, phone, loggedIn: true });
    useBugsnag().setUser(id);

    const action = {
      type: LOGGED_IN,
      payload: {
        phone,
      },
    };

    await dispatch(action);
    await navigateTo({ name: 'apply' });
  } catch (e) {
    useBugsnag().notify(e);
    if (get(e, 'response.status') === 401) {
      authError.value = true;
    } else {
      serverError.value = true;
    }
    submitting.value = false;
  }
}

</script>
